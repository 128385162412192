<template>
  <div>
    <div class="content-wrapper">
      <!-- Lines -->
      <section class="content-lines-wrapper">
        <div class="content-lines-inner">
          <div class="content-lines"></div>
        </div>
      </section>
      <!-- Footer -->
      <footer class="main-footer dark" id="contact">
        <div class="container">
          <div class="row">
            <div class="col-md-4 mb-30">
              <div class="item fotcont">
                <div class="fothead">
                  <h6>Phone</h6>
                </div>
                <p>+91 98 98 78 37 39</p>
              </div>
              <div class="item fotcont mt-20">
                <div class="fothead">
                  <h6>Email</h6>
                </div>
                <p>livartmetalsllp@gmail.com</p>
              </div>
            </div>
            <div class="col-md-4 mb-30">
              <div class="item fotcont">
                <div class="fothead">
                  <h6>Our Address</h6>
                </div>
                <p>
                  Survey No. 595, P6, Plot No. 10, Opp. Astia Industries, Jetpar
                  rood, At. Rangpar, Morbi-363642, Gujarat, India
                </p>
              </div>
            </div>
            <div class="col-md-4 mb-30">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3677.005097046237!2d70.871718514782!3d22.839300928884406!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39598d421b03eed3%3A0xa14375afec06093!2sLivart%20Metals%20LLP!5e0!3m2!1sen!2sin!4v1632631410450!5m2!1sen!2sin"
                width="100%"
                height="200"
                style="border: 0"
                allowfullscreen=""
                loading="lazy"
              ></iframe>
            </div>
          </div>
        </div>
        <div class="sub-footer">
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <div class="text-center">
                  <p>
                    &copy; 2023 Astia. All right reserved. Developed by <a target="_blank" href="https://aptechinfoway.com/">APTech</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: "bottomNav",

  components: {},
};
</script>
