<template>
  <div  class="d-flex ">
    <Header class="side-nav-header wrapper__aside"  @my-event="myButtonEventHandler"></Header>
    <div  class="main-container-div" >
    <header  class="header slider-fade">
    <div id="homenav" class="owl-carousel owl-theme">
        <div class="text-right item bg-img" data-overlay-dark="3" data-background="img/slider/1.jpg">
            <div class="v-middle caption">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 offset-md-5">
                            <div class="o-hidden">
                                <h1>Quality Product that define the class</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right item bg-img" data-overlay-dark="4" data-background="img/slider/2.jpg">
            <div class="v-middle caption">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 offset-md-5">
                            <div class="o-hidden">
                                <h1>The Most Powerful Sink for your kitchen</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="text-right item bg-img" data-overlay-dark="4" data-background="img/slider/3.jpg">
            <div class="v-middle caption">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7 offset-md-5">
                            <div class="o-hidden">
                                <h1>Modular Handmade kitchen sink</h1>
                                <!-- <p>Architecture viverra tellus nec massa dictum the euismoe.
                                    <br>Curabitur viverra the posuere aukue velit.
                                </p>
                                <div class="butn-light mt-30 mb-30"><a href="#"><span>Read More</span></a></router-link></div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Corner -->
    <div class="hero-corner"></div>
    <div class="hero-corner3"></div>
    </header>
        <div class="content-wrapper">
            <!-- Lines -->
            <section class="content-lines-wrapper">
                <div class="content-lines-inner">
                    <div class="content-lines"></div>
                </div>
            </section>
            <!-- About -->
            <section id="about" class="about section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-6 mb-30 animate-box" data-animate-effect="fadeInUp">
                            <h2 class="section-title">About <span>Livart</span></h2>
                            <p>You may already have some sense of how you want your kitchen to look and feel.</p>

                            <p>Perhaps you've already chosen your cabinets and counter tops and selecting your kitchen sink and foucet amy seem like a quick last step. And while we try to make the process as simple as possible, there one a number of style and functionality options you'll want to consider before making your final selections, We'll walk you through those options in the section that follows and help you settle on a sink and faucet combination that makes sense for you.</p>
                        </div>
                        <div class="col-md-6 animate-box" data-animate-effect="fadeInUp">
                            <div class="about-img">
                                <div class="img"> <img src="img/about.jpg" class="img-fluid" alt=""> </div>
                                <div class="about-img-2 about-buro">Morbi Office</div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="projects" class="projects section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="section-title">Our <span>Products</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="pro-ca owl-carousel owl-theme">
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/single-bowl-1.jpg" alt="single-bowl-1">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=single_bowl_1"><a href="product-details?product=single-bowl-1">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=single_bowl_1"><a href="product-details?product=single-bowl-1">Single Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=single_bowl_1"><a href="product-details?product=single-bowl-1"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/double-bowl-1.jpg" alt="double-bowl-1">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=double_bowl_1"><a href="product-details?product=double-bowl-1">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=double_bowl_1"><a href="product-details?product=double-bowl-1">Double Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=double_bowl_1"><a href="product-details?product=double-bowl-1"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/drain-board-1.jpg" alt="drain-board-1">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=drain_bowl_1"><a href="product-details?product=drain-board-1">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=drain_bowl_1"><a href="product-details?product=drain-board-1">Drain Board</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=drain_bowl_1"><a href="product-details?product=drain-board-1"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/single-bowl-2.jpg" alt="single-bowl-2">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=single_bowl_2"><a href="product-details?product=single-bowl-2">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=single_bowl_2"><a href="product-details?product=single-bowl-2">Single Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=single_bowl_2"><a href="product-details?product=single-bowl-2"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/double-bowl-2.jpg" alt="double-bowl-2">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=double_bowl_2"><a href="product-details?product=double-bowl-2">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=double_bowl_2"><a href="product-details?product=double-bowl-2">Double Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=double_bowl_2"><a href="product-details?product=double-bowl-2"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/drain-board-2.jpg" alt="drain-board-2">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=drain_bowl_2"><a href="product-details?product=drain-board-2">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=drain_bowl_2"><a href="product-details?product=drain-board-2">Drain Board</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=drain_bowl_2"><a href="product-details?product=drain-board-2"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/single-bowl-3.jpg" alt="single-bowl-3">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=single_bowl_3"><a href="product-details?product=single-bowl-3">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=single_bowl_3"><a href="product-details?product=single-bowl-3">Single Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=single_bowl_3"><a href="product-details?product=single-bowl-3"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/single-bowl-4.jpg" alt="single-bowl-4">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=single_bowl_4"><a href="product-details?product=single-bowl-4">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=single_bowl_4"><a href="product-details?product=single-bowl-4">Single Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=single_bowl_4"><a href="product-details?product=single-bowl-4"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                                <div class="item">
                                    <div class="position-re o-hidden">
                                        <img src="img/product/double-bowl-4.jpg" alt="double-bowl-4">
                                    </div>
                                    <div class="con">
                                        <h6><router-link to="/product-details?name=double_bowl_4"><a href="product-details?product=double-bowl-4">Armani Series</a></router-link></h6>
                                        <h5><router-link to="/product-details?name=double_bowl_4"><a href="product-details?product=double-bowl-4">Double Bowl</a></router-link></h5>
                                        <div class="line"></div>
                                        <router-link to="/product-details?name=double_bowl_4"><a href="product-details?product=double-bowl-4"><i class="ti-arrow-right"></i></a></router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 text-center">
                            <button class="butn-dark mt-15"><router-link to="/product"><a><span>View All Product</span></a></router-link></button>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Services -->
            <section id="services" class="services section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col-md-12">
                            <h2 class="section-title">Our <span>Speciality</span></h2>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/handmade-square-sinks.png" alt="handmade-square-sinks">
                                    <h5>Handmade Square Sink</h5>
                                    <div class="line"></div>
                                    <p>Handmade square sinks are made with high precision for ultimate luxury and premium look. They meet the requirement of your kitchen while giving a modem and sophisticated look.</p>
                                    <div class="numb">01</div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/drain-board.png" alt="drain-board">
                                    <h5>Drain Board</h5>
                                    <div class="line"></div>
                                    <p>Grooves in the shape of letter 'X' are made to channelise and ease the flow of water and food waste towards the drain hole.</p>
                                    <div class="numb">02</div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/steel-thickness.png" alt="steel-thickness">
                                    <h5>Steel Thickness</h5>
                                    <div class="line"></div>
                                    <p>Livart uses 1.0mm thick steel for all its handmade sinks, which makes the sink vulnerable to denting bending, even after years of receiving hand knocks from utensils.</p>
                                    <div class="numb">03</div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/aisi-ss-grade-304.png" alt="">
                                    <h5>Aisi SS grade 304</h5>
                                    <div class="line"></div>
                                    <p>Made with american international steel institute 83304 food grade steel with 8% nickel and 18% chromium, this sink ensures extraordinary reality, strength, durability and superior performance, while maintaining hygiene.</p>
                                    <div class="numb">04</div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/radius-10-curve.png" alt="">
                                    <h5>Radius 10 curve</h5>
                                    <div class="line"></div>
                                    <p>10mm radius curve on the corners of the square sinks enables to avoid sticking of food waste and makes it easier to clean, while maintaining hygiene.</p>
                                    <div class="numb">05</div>
                                </a>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="item">
                                <a>
                                    <img src="img/icons/dual-sound-absorption.png" alt="">
                                    <h5>Dual Sound absorption</h5>
                                    <div class="line"></div>
                                    <p>Grey coloured ceramic undercoating with rubber pad, dampens the noise from sink during cleaning of utensils and also enhances the life of the sink.</p>
                                    <div class="numb">06</div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="testimonials">
                <div class="background bg-img bg-fixed section-padding pb-0" data-background="img/banner2.jpg" data-overlay-dark="3">
                    <div class="container">
                        <div class="row">
                            <!-- Promo video -->
                            <div class="col-md-6">
                                
                            </div>
                            <!-- Testiominals -->
                            <div class="col-md-5 offset-md-1">
                                <div class="testimonials-box animate-box" data-animate-effect="fadeInUp">
                                    <div class="head-box">
                                        <h4>What Client's Say?</h4>
                                    </div>
                                    <div class="owl-carousel owl-theme">
                                        <div class="item"> <span class="quote"><img src="img/quot.png" alt=""></span>
                                            <p>Architect dapibus augue metus the nec feugiat erat hendrerit nec. Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.</p>
                                            <div class="info">
                                                <div class="author-img"> <img src="img/team/1.jpg" alt=""> </div>
                                                <div class="cont">
                                                    <h6>Jason Brown</h6> <span>Crowne Plaza Owner</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item"> <span class="quote">
                                                <img src="img/quot.png" alt="">
                                            </span>
                                            <p>Interior dapibus augue metus the nec feugiat erat hendrerit nec. Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.</p>
                                            <div class="info">
                                                <div class="author-img"> <img src="img/team/2.jpg" alt=""> </div>
                                                <div class="cont">
                                                    <h6>Emily White</h6> <span>Armada Owner</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item"> <span class="quote">
                                                <img src="img/quot.png" alt="">
                                            </span>
                                            <p>Urban dapibus augue metus the nec feugiat erat hendrerit nec. Duis ve ante the lemon sanleo nec feugiat erat hendrerit necuis ve ante.</p>
                                            <div class="info">
                                                <div class="author-img"> <img src="img/team/4.jpg" alt=""> </div>
                                                <div class="cont">
                                                    <h6>Jesica Smith</h6> <span>Alsa Manager</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- Clients -->
            <section class="clients">
                <div class="container">
                    <div class="row">
                        <div class="col-md-7">
                            <div style="height:120px"></div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    <Footer id="contact"></Footer>
    </div>
  </div>
</template>

<script>
import Header from "../components/Header";
import Footer from "../components/Footer";

export default {
  name: "App",

  components: {
    Header,
    Footer,
  },
  data() {
      return {
          url_data: null,
          to:null,
          from:null,
        };
    },
    mounted() {
      // console.log("this.$route.params",this.$route.params.name)
      window.scrollTo(0, 0);
    },
    computed: {

    },
    // beforeRouteEnter(to, from, next) {

    //     console.log("to...",to)
    //     console.log("from...",from)
    //     if(from.name == "Product"){
    //         this.goToNewRoute()
    //     }
    //     next();
    // },
  watch: {
    "$route.params": {
      handler: async function () {
        console.log("this.$route.query",this.$route.query.name,screen.width)
        let tempPx =630
        if(screen.width <769) tempPx =900
        var ele = document.getElementById(this.$route.query.name);
         window.scrollTo({
        left: this.$route.query.name != "homenav" ?  ele.offsetLeft + tempPx:ele.offsetLeft,
        top: this.$route.query.name != "homenav" ?ele.offsetTop + tempPx :ele.offsetTop ,
        behavior: "smooth",
      });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    // myButtonEventHandler(x) {
    //   var ele = document.getElementById(x);
    //   window.scrollTo({
    //     left: ele.offsetLeft + 630,
    //     top: ele.offsetTop + 630,
    //     behavior: "smooth",
    //   });
    // },
    // goToNewRoute() {
    //     this.$router.push("/")
    //     window.location.reload()
    // },
  },
};
</script>
<style>
.owl-carousel .bg-img {
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .owl-carousel .bg-img {
    background-repeat: no-repeat;
    background-size: auto 100vh;
  }
  .owl-item {
    width: 100vw !important;
  }
  .main-container-div {
    width: 100%;
    overflow: scroll;
  }
  .pro-ca .owl-stage-outer {
    height: 205.5px;
  }

}
@media screen and (min-width: 768px) {
  .side-nav-header {
    width: 21%;
    overflow: scroll;
  position: fixed;

  }
  .main-container-div {
    width: 79%;
    overflow: scroll;
    margin-left: 20%;
  }

/* .wrapper__aside {
  position: fixed;
} */
}
</style>
