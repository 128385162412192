<template>
  <div>
    <!-- <div id="loading-area"></div> -->
    <!-- <body data-spy="scroll" data-target=".navbar" data-offset="50"> -->

    <div class="progress-wrap cursor-pointer">
      <svg
        class="progress-circle svg-content"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
      </svg>
    </div>

    <a href="#" class="js-bauen-nav-toggle bauen-nav-toggle"><i></i></a>
    <aside id="bauen-aside">
      <!-- Logo -->
      <div class="bauen-logo">
        <a >
          <router-link to="/?name=homenav">
          <img src="img/logo.png" class="logo-img" alt="" />
        </router-link>
        </a>
      </div>
      <!-- Menu -->
      <nav class="bauen-main-menu">
        <ul>
          <li><a @click="buttonClickHandler('homenav')"><router-link to="/?name=homenav">Home</router-link></a></li>
          <li><a @click="buttonClickHandler('about')"><router-link to="/?name=about">About</router-link></a></li>
          <li><a @click="buttonClickHandler('projects')"><router-link to="/?name=projects">Products</router-link></a></li>
          <li><a @click="buttonClickHandler('services')"><router-link to="/?name=services">Services</router-link></a></li>
          <li><a @click="buttonClickHandler('contact')"><router-link to="/?name=contact">Contact</router-link></a></li>
          <!-- <li><a v-on:click="scrollToElement({behavior: 'smooth'})" href="/">Home</a></li>
          <li><a @click="buttonClickHandler("about")"  onclick='scroll("about");' >About</a></li>
          <li><a href="/?class=projects">Products</a></li>
          <li><a href="/?class=services">Services</a></li>
          <li><a href="/?class=about">Contact</a></li> -->
        </ul>
      </nav>
      <!-- Sidebar Footer -->
      <div class="bauen-footer">
        <ul>
          <li>
            <a href="#"><i class="ti-facebook"></i></a>
          </li>
          <li>
            <a href="#"><i class="ti-twitter"></i></a>
          </li>
          <li>
            <a href="#"><i class="ti-instagram"></i></a>
          </li>
          <li>
            <a href="#"><i class="ti-pinterest"></i></a>
          </li>
        </ul>
        <h6>+91 98 98 78 37 39</h6>
      </div>
    </aside>
    <!-- Main -->
    <!-- <div id="bauen-main"></div> -->
  </div>
</template>

<script>
export default {
  name:"HeaderC",
  methods: {
    buttonClickHandler(X) {
      this.$emit('my-event', X)
    }
  }
}
</script>
<style>
    /* <aside id="bauen-aside" style="position:relative;width:fit-content;max-width: 100%;"> */
@media screen and (min-width:768px) {
  #bauen-aside{
    position: relative;
    width:fit-content;
    max-width: 100%;
  }
}
</style>